
import "./cart.css";
export const Cart = () => {
  return (
    <div className="cart">
      <div>
        <h1>Cart</h1>
      </div>

      <h1>Coming Soon 😎</h1>
    </div>
  );
};
